<template>
  <div>
    <SimpleHeader></SimpleHeader>
    <div class="login-box">
      <h3 class="title">{{ title }}</h3>
      <!-- <div class="tip">{{ $t(100) }}</div> -->
      <el-form
        ref="form"
        :rules="rules"
        :model="form">
        <div v-if="configObj.isOpenGlobalVerifyCode==='1'&&showVerify">
          <div class="item" v-if="!userInfo.mobile">
            <div class="label mb5">{{ $t(101) }}</div>
            <el-form-item>
              <el-input v-model="userInfo.email" disabled>
              </el-input>
            </el-form-item>
          </div>
          <div class="item" v-else>
            <div class="label mb5">{{ $t(102) }}</div>
            <el-form-item>
                <el-input v-model="userInfo.mobile" disabled>
                <el-select slot="prepend" v-model="userInfo.areaCode" class="code-select" filterable popper-class="bfff" disabled>
                  <el-option
                    v-for="item in countryList"
                    :key="item.countryCode"
                    :label="item.englishName+'+'+item.areaCode"
                    filterable
                    :value="item.countryCode+'-'+item.areaCode"
                  ></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="item" v-if="configObj.isOpenGlobalVerifyCode==='1'&&configObj.isOpenUpdatePwdVerifyCode==='1'">
          <div class="label mb5">{{ $t(122) }}</div>
          <el-form-item
            prop="verifyCode">
              <el-input v-model="form.verifyCode">
                <el-button slot="append" :disabled="text!==sendText" type="text" @click="sendCode">
                  <span :class="{'countDown': sendText !== text}">{{ sendText }}</span>
                </el-button>
            </el-input>
          </el-form-item>
        </div>
        <div>
          <div class="label mb5">{{ $t(152) }}</div>
          <el-form-item
            prop="oldPasswd">
              <el-input type="password" v-model="form.oldPasswd" show-password></el-input>  
          </el-form-item>
        </div>
        <div>
          <div class="label mb5">{{ $t(153) }}</div>
          <el-form-item
            prop="password">
              <el-input type="password" v-model="form.password" show-password></el-input>  
          </el-form-item>
        </div>
        <div class="pt20 pb10"><el-button type="primary" @click="doSubmit" :loading="loading">{{ $t(127) }}</el-button></div>
        <el-button type="text" @click="$router.go(-1)" class="el-icon-back fs16"></el-button>
        <!-- <el-button type="text" @click="$router.push('/register')">{{ $t(104) }}</el-button> -->
      </el-form>
      <div class="corpyrights">© 2017 - 2021 {{ configObj.appName }} All rights reserved</div>
    </div>
  </div>
</template>

<script>
import loginMixins from '@/mixins/login.js'
import SimpleHeader from '@/components/SimpleHeader'
import { updateLoginPwd, updateFundsPwd, getCodeByToken } from '@/api'
import utils from '@/utils/misc'
export default {
  name: 'UpdatePassword',
  components: { SimpleHeader },
  mixins: [loginMixins],
  data() {
    return {
      form: {
        password: '',
        oldPasswd: '',
        verifyCode: ''
      },
      sendText: '',
      text: this.$t('123'),
      timer: null
    }
  },
  computed: {
    configObj() {
      return window.configObj
    },
    rules() {
      return {
        ...this.commonRules
      }
    },
    userInfo() {
      return {
        ...this.$store.state.userInfo,
        mobile: this.$store.state.userInfo.mobile ? this.$store.state.userInfo.mobile.replace(/(\d){1}\d+(\d{3})/, '$1****$2') : '',
        email: this.$store.state.userInfo.email ? this.$store.state.userInfo.email.replace(/(\d){1}\d+(\d{3})/, '$1****$2') : '',
      }
    },
    title() {
      return this.$route.name === 'UpdateLoginPwd' ? this.$t(150) : this.$t(151)
    },
    showVerify() {
      const key = this.$route.name === 'UpdateLoginPwd'?'isOpenUpdatePwdVerifyCode' :'isOpendUpdateFundsPwdVerifyCode'
      return this.configObj[key] === '1'
    }
  },
  beforeMount() {
    this.sendText = utils.getLs('sendText') || this.text
    this.setTimer()
  },
  methods: {
    setTimer() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (isNaN(this.sendText)) return
        if (this.sendText <= 1) {
          this.sendText = this.text
          clearInterval(this.timer)
          utils.removeLs('sendText')
        } else {
          this.sendText--
          utils.setLs('sendText', this.sendText)
        }
      }, 1000)
    },
    sendCode() {
      if (this.loading) return
      const obj = {
        scene: this.$route.name === 'UpdateLoginPwd' ? 'EDIT_PASSWD' : 'EDIT_FUNDS_PASSWD',
        type: this.userInfo.mobile ? 'MOBILE' : 'EMAIL'
      }
      this.loading = true
      getCodeByToken(obj).then(() => {
        this.sendText = 60
        this.setTimer()
      }).finally(() => {
        this.loading = false
      })
    },
    doSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const obj = {...this.form}
          const api = this.$route.name === 'UpdateLoginPwd'? updateLoginPwd : updateFundsPwd
          if (!obj.verifyCode) {
            obj.verifyCode = '123456'
          }
          api(obj).then(res => {
            if (this.$route.name === 'UpdateLoginPwd') {
              utils.removeLs('token')
              this.$router.push('Login')
            } else {
              this.$router.go(-1)
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-box{
    color: #1E2329;
    width: 400px;
    margin: 10vh auto;
    .title{
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 60px;
    }
    .tab{
      margin: 40px 0px 20px 0px;
      color: #707A8A;
      font-size: 16px;
      .item{
        display: inline-block;
        cursor: pointer;
        padding: 8px 24px;
        margin-right: 12px;
        &.active{
          background-color: #F5F5F5;
          color: #1E2329;
          border-radius: 8px;
        }
      }
    }
    .el-input__inner{
      height: 46px;
      line-height: 46px;
    }
  }
  .corpyrights{
    position: fixed;
    bottom: 10px;
    text-align: center;
    font-size: 12px;
    width: 100%;
    left: 0;
  }
</style>